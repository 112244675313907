<template lang="pug">
button.button(
  v-if='isButton',
  :class='[`${variant}-btn`, { outlined, fullWidth, withShadow }]',
  @click.stop='$emit("click")'
) {{ label }}
a.button(
  v-else-if='isLink',
  :href='path.href',
  :class='[`${variant}-btn`, { outlined, fullWidth, withShadow }]',
  target='_blank'
) {{ label }}
router-link.button(v-else, :class='[`${variant}-btn`, { outlined, fullWidth, withShadow }]', :to='path') {{ label }}
</template>

<script>
export default {
  name: 'CommonButton',
  props: {
    label: {
      type: String,
      required: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    withShadow: {
      type: Boolean,
      default: false
    },
    path: {
      type: Object,
      default: () => {
        'Home'
      }
    },
    isLink: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.button {
  font-family: 'Libre Franklin', sans-serif;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  border: none;
  width: fit-content;
  font-weight: 600;
  max-width: 80vw;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  text-align: center;

  &.fullWidth {
    width: 100%;
  }

  &.withShadow {
    box-shadow: 0px 5px 12px 0px rgba(184, 184, 184, 0.4);
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &.primary-btn {
    background-color: $darkBlue;
    border-color: $darkBlue;
    color: $white;

    &.outlined {
      background-color: transparent;
      color: $darkBlue;
    }
  }

  &.white-btn {
    background-color: $white;
    border-color: $white;
    color: $darkBlue;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: $green;
    }

    &.outlined {
      background-color: transparent;
      color: $white;
    }
  }

  &.green-btn {
    background-color: $green;
    border-color: $green;
    color: $darkBlue;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: $darkGreen;
    }

    &.outlined {
      background-color: transparent;
      color: $green;
    }
  }

  &.orange-btn {
    background-color: $orange;
    border-color: $orange;
    color: $white;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: $darkOrange;
    }

    &.outlined {
      background-color: transparent;
      color: $orange;
    }
  }
}
</style>
